<template>
  <div class="cashapp-container">
    <!-- <h1 class="cashapp-header">Payment Center</h1> -->

    <div class="order-details">
      <p>Amount: <span class="order-info">$ {{ (amount/100).toFixed(2) }} </span></p>
      <p>OrderId: <span class="order-info"> {{ orderId }} </span></p>
    </div>

    <form id="payment-form" @submit.prevent="handleCashappPayment">
      <div id="payment-element"></div>
      <!-- <br/> -->
      <button id="PayNow" type="submit" class="cashapp-button">Cash APP Pay</button>
    <!-- <button id="PayNow" type="submit" class="cashapp-button">Cash APP Pay</button> -->
      <div id="error-message"></div>
    </form>
     <!--<div id="PayNow" class="btn-wrap" @click.stop="handleCashappPayment">Cash APP Pay</div> -->
    <!--  <button id="PayNow" type="button" class="cashapp-button" @click.stop="handleCashappPayment">Cash APP Pay</button>-->
    <div id="ortest" class="center-block"></div>
    <div id="applepay-element"></div>
    <div id="payment-message"></div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import apiClient,  {API_URL_CREATE_ORDER,API_URL_QUERY_ORDER,API_URL_CREATE_PAYMENT}  from '@/services/api'; // 引入封装好的 HTTP 请求

export default {
  data() {
    return {
      stripe: null,
      clientSecret: null,
      elements: null,
      amount: null,  // 从 URL 查询参数中获取传入的 amount
      encryptedString: this.$route.params.encryptedString,  // 从 URL 获取加密串
      orderId: null, // 从 URL 查询参数中获取传入的 orderId
      payType: null,
      errorMsg: null,
      returnUrl: null,
      wayCode: null,
      platformWebsite: null,
      randomNo: null
    };
  },
  async mounted() {
    document.title = 'Payment Center';
    document.getElementById('PayNow').style.display = 'none';
    try {
      const response = await apiClient.post(API_URL_CREATE_PAYMENT, {
        encryptedString: this.encryptedString
      });
      this.clientSecret = response.data.clientSecret;
      this.amount = response.data.amount;
      this.orderId = response.data.orderId;
      this.wayCode = response.data.wayCode;
      this.platformWebsite = response.data.platformWebsite;
      this.payType = response.data.payType;
      this.errorMsg = response.data.errorMsg;
      this.returnUrl = response.data.returnUrl;
      this.randomNo = Math.random().toString(36).slice(-8);
      if(this.errorMsg === ''){
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      //locale: "en" // 设置默认显示语种   en 英文 cn 中文 auto 自动获取语种
      const elements = this.stripe.elements({ locale: "en",clientSecret: this.clientSecret, loader: 'never' });
      this.elements = elements;
      //如果是苹果pay
      if(this.wayCode === 'CASH_APP'){
        if(this.payType === 2 || this.payType === 4){
          const paymentElement = elements.create('payment');
          paymentElement.mount('#payment-element');
          document.getElementById('PayNow').style.display = 'block';
        }else if(this.payType === 3){
          const clientSecret = this.clientSecret;
          const paymentElement = elements.create("expressCheckout", {
                  mode: "payment",
                  paymentMethodType: "card",
                  clientSecret,
                  supportedPaymentMethods: ["apple_pay"], // 仅支持 Apple Pay
          });
          paymentElement.mount('#payment-element');
          paymentElement.on("confirm", async (event) => {
            console.log(event)
            const { error } = await this.stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: this.returnUrl
              },
            });
            if (error) {
              console.error(error)
              document.getElementById('error-message').textContent = this.errorMsg;
            }
          });
        }else if(this.payType === 1){
          //判断设备是否支持applepay
          var u = navigator.userAgent;
          var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          //var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
          if(window.ApplePaySession !== undefined){
            document.getElementById('ortest').innerHTML = '<p><span class="order-info">or</span></p>';
          }
          const clientSecret = this.clientSecret;
          if(isiOS){
          //创建applepay
          const applepayElement = elements.create("expressCheckout", {
                  mode: "payment",
                  paymentMethodType: "card",
                  clientSecret,
                  supportedPaymentMethods: ["apple_pay"], // 仅支持 Apple Pay
          });
          applepayElement.mount('#applepay-element');
          applepayElement.on("confirm", async (event) => {
            console.log(event)
            const { error } = await this.stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: this.returnUrl
              },
            });
            if (error) {
              console.error(error)
              document.getElementById('error-message').textContent = error;
              this.sendOrderInfo(this.orderId,this.randomNo + ' APPLE_wayCode1 confirm error',error);
            }
          });
          } 
          //const cashappElements = this.stripe.elements({ locale: "en",clientSecret: this.clientSecret, loader: 'never',externalPaymentMethodTypes: 'cashapp' });
          //创建cashapp
          const paymentElement = elements.create('payment', {
            readOnly: true,
            paymentMethodOrder: ["cashapp"]
          });
          paymentElement.mount('#payment-element');
          if(!isiOS){
          setTimeout(() => {
              this.handleCashappPaymentAuto();
              this.enabledButton(); 
          }, 500);
          }
          document.getElementById('PayNow').style.display = 'block';
        }
      }else if(this.wayCode === 'APPLE_PAY'){
          const clientSecret = this.clientSecret;
          const paymentElement = elements.create("expressCheckout", {
                  mode: "payment",
                  paymentMethodType: "card",
                  clientSecret,
                  supportedPaymentMethods: ["apple_pay"], // 仅支持 Apple Pay
          });
          paymentElement.mount('#payment-element');
          paymentElement.on("confirm", async (event) => {
            console.log(event)
            const { error } = await this.stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: this.returnUrl
              },
            });
            if (error) {
              console.error(error)
              document.getElementById('error-message').textContent = error;
              this.sendOrderInfo(this.orderId,this.randomNo + ' APPLE_wayCode3 confirm error',error);
            }
          });
      }else{
        const paymentElement = elements.create('payment');
        paymentElement.mount('#payment-element');
        document.getElementById('PayNow').style.display = 'block';
      }
      // 1 秒后自动提交表单
      if(this.platformWebsite !== 1){
        setTimeout(() => {
          if(this.wayCode === 'CASH_APP' && this.payType === 2){
            this.handleCashappPaymentAuto();
            this.enabledButton();
          }    
         }, 500);
      }else{
        var payNowButton = document.getElementById('PayNow');
        payNowButton.textContent = 'Pay';
        var orderNo = this.orderId;
        setInterval(function() {
          apiClient.post(API_URL_QUERY_ORDER + '?orderId=' + orderNo, {
            orderId2: orderNo
          }).then(function (response) {
            // 处理成功情况
            console.log(response.data); // 这里是返回的数据
            var state = response.data.data.state;
            console.log(state); 
          if(state == 2){
            console.log(222222); 
            window.location = 'https://www.minicadesmobilegames.com/success.html';
            window.location.href = 'https://www.minicadesmobilegames.com/success.html';          
          }
          });
          
        }, 3000); // 每隔1秒执行一次
        }
      }else{
          document.getElementById('error-message').textContent = this.errorMsg;
      }
    } catch (error) {
      console.error('error', error);
      document.getElementById('error-message').textContent = error;
      this.sendOrderInfo(this.orderId,this.randomNo + ' auto payment error',error);
    }
  },
  methods: {
    async handleCashappPaymentAuto() {
      try {
        this.disabledButton();
        this.sendOrderInfo(this.orderId,this.randomNo + ' auto cashapp','no error');
        const {error} = await this.stripe.confirmCashappPayment(this.clientSecret, {
          payment_method: {
            type: 'cashapp',
          },
          return_url: this.returnUrl, // return url after payment success
        });

        if (error) {
          // 如果支付发生错误，显示错误信息
          document.getElementById('error-message').textContent = error.message;
          this.sendOrderInfo(this.orderId,this.randomNo + ' auto submit cashapp error',error.message);
        }
      } catch (error) {
        console.error('pay failed:', error);
        document.getElementById('error-message').textContent = error;
        this.sendOrderInfo(this.orderId,this.randomNo + ' auto cashapp catch error',error);
      }
    },
    async orderQuery() {
      const response = await apiClient.post(API_URL_QUERY_ORDER, {
        orderId: this.orderId
      });
      this.state = response.data.state;
      if(this.state === 2){
        window.location = 'https://www.minicadesmobilegames.com/success.html';
      }
    },
    async handleCashappPayment() {
      if(this.platformWebsite === 1){
        this.handlePayment()
      }else{
        try {
          this.disabledButton();
          this.sendOrderInfo(this.orderId,this.randomNo + ' manual cashapp','no error');
          const {error} = await this.stripe.confirmCashappPayment(this.clientSecret, {
            payment_method: {
              type: 'cashapp',
            },
            return_url: this.returnUrl, // return url after payment success
          });
  
          if (error) {
            // 如果支付发生错误，显示错误信息
            document.getElementById('error-message').textContent = error.message;
            this.sendOrderInfo(this.orderId,this.randomNo + ' manual submit cashapp error',error.message);
          }
        } catch (error) {
          console.error('pay failed:', error);
          document.getElementById('error-message').textContent = error;
          this.sendOrderInfo(this.orderId,this.randomNo + ' manual submit cashapp catch error',error);
        }
      }
    },
    async handlePayment() {
      try {
        const elements = this.elements;
        this.stripe.confirmPayment({
          elements,
          confirmParams: {
            // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
            return_url: this.returnUrl,
          },
        })
        .then(function(result) {
          if (result.error) {
            // Inform the customer that there was an error.
            //document.getElementById('error-message').textContent = result.error;
            this.sendOrderInfo(this.orderId,this.randomNo + 'manual handlePayment error',result.error);
          }
        });
      } catch (error) {
        console.error('pay failed:', error);
        document.getElementById('error-message').textContent = error;
        this.sendOrderInfo(this.orderId,this.randomNo + ' manual handlePayment catch error',error);
      }
    },
    async sendOrderInfo(orderId,submitType,errMsg) {
      try {
        apiClient.post(API_URL_CREATE_ORDER, {
          userAgent: navigator.userAgent,
          orderId: orderId,
          submitType: submitType,
          errMsg: errMsg
        });
      } catch (error) {
        console.error('error:', error);
      }
    },
    async disabledButton() {
      try {
        var payNowButton = document.getElementById('PayNow');
        payNowButton.textContent = 'Please wait.payment processing...';
        payNowButton.disabled = true;
        document.getElementById('ortest').innerHTML = 'If you have not completed the payment and wish to continue using <strong>cashapp</strong> payment, please refresh this page!'; 
      } catch (error) {
        console.error('pay failed:', error);
        document.getElementById('error-message').textContent = error;
        this.sendOrderInfo(this.orderId,this.randomNo + ' disabledButton error',error);
      }
    },
    async enabledButton() {
      try {
        var payNowButton = document.getElementById('PayNow');
        var i = 3;
        var interval = setInterval(function() {
          if(i>0){
          payNowButton.textContent = 'The order is being pulled up, you can click on payment again in '+i+' seconds';
          i--;
          }else{
            payNowButton.textContent = 'Cash APP Pay';
            clearInterval(interval);
          }
        }, 1000);
        payNowButton.disabled = false;
        document.getElementById('ortest').innerHTML = ''; 
      } catch (error) {
        console.error('pay failed:', error);
        document.getElementById('error-message').textContent = error;
        this.sendOrderInfo(this.orderId,this.randomNo + ' enabledButton error',error);
      }
    },
  },
};
</script>

<style scoped>
/* 容器布局 */
.cashapp-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* 标题样式 */
.cashapp-header {
  color: #00d632; /* Cash App 绿色 */
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
}

/* 订单详情样式 */
.order-details {
  margin-bottom: 20px;
  font-size: 1.2em;
}

/* 订单信息样式 */
.order-info {
  font-weight: bold;
  color: #333;
}

.center-block {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

/* 按钮样式 */
.cashapp-button {
  background-color: #00d632; /* Cash App 绿色 */
  color: white;
  padding: 15px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.cashapp-button:hover {
  background-color: #00b828; /* 深绿色悬停效果 */
}

/* 错误信息样式 */
#error-message {
  color: red;
  margin-top: 10px;
}
.btn-wrap {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
  border-radius: 10px;
  background-color: #00b828;
  color: white;
  box-sizing: border-box;
  margin-top: 20px;
}
</style>
