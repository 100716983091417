// src/services/api.js

import axios from 'axios';

// api url 列表
export const API_URL_CREATE_PAYMENT = '/api/payment/create-payment-intent'
export const API_URL_CREATE_ORDER = '/api/order/order-info'
export const API_URL_QUERY_ORDER = 'https://www.minicadesmobilegames.com/pay/query_order.php'

// 创建 Axios 实例
const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, // 基础 URL 来源于环境变量
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 500000, // 超时时间
});

// 请求拦截器
apiClient.interceptors.request.use(
    config => {
        // 如果有需要可以在这里添加 Authorization header
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
apiClient.interceptors.response.use(
    response => response,
    error => {
        // 可以在这里处理错误，展示消息提示等
        return Promise.reject(error);
    }
);

export default apiClient;
